import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListSolRhRestcService {

  constructor(private httpClient: HttpClient) { }

  solicitudesIncapaciadParaRH(): Observable<any> {
    let path = environment.msListSolicitudesRh;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoIncapacidad } from 'src/app/api/tipo-incapacidad/tipo-incapacidad';
import { TipoIncapacidadReg } from 'src/app/api/tipo-incapacidad/tipo-incapacidad-reg';
import { TipoIncapacidadPsService } from 'src/app/procs/tipo-incapacidad/tipo-incapacidad-ps.service';

@Component({
  selector: 'axks-tipo-incapacidad',
  templateUrl: './tipo-incapacidad.component.html',
  styleUrls: ['./tipo-incapacidad.component.scss']
})
export class TipoIncapacidadComponent extends BaseMainComponent<TipoIncapacidadPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvider: SessionClientProvider,
    private psService: TipoIncapacidadPsService) {

    super(deviceService, messageService, screenModeService, sessionProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as TipoIncapacidad,
      minimumItem: {} as TipoIncapacidad,
      registerItem: {} as TipoIncapacidadReg
    } as ComponentItem;
    return empty;
  }

  getService(): TipoIncapacidadPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'ID ', field: 'id', inAll: true },
      { header: 'Tipo Incapacidad', field: 'tipoIncapacidad', inAll: true },
      { header: 'Comentario', field: 'comentario', inAll: true }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return "Tipo de incapacidad";
  }
}

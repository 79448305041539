import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RestClientModule } from '@axks/net';
import { HttpClientModule } from '@angular/common/http';
import { ActionsBarModule, BaseMessages, CommonsModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { IncapacidadComponent } from './views/incapacidad/incapacidad/incapacidad.component';
import { IncapacidadFormComponent } from './views/incapacidad/incapacidad-form/incapacidad-form/incapacidad-form.component';

import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { ListSolLiderFormComponent } from './views/list-sol-lider-form/list-sol-lider-form.component';


import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ListSolRhFormComponent } from './views/list-sol-rh-form/list-sol-rh-form.component';

import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ContextMenuModule } from 'primeng/contextmenu';
import { KeyFilterModule } from 'primeng/keyfilter';
import { HomeComponent } from './views/home/home.component';
import { TipoIncapacidadComponent } from './views/tipo-incapacidad/tipo-incapacidad/tipo-incapacidad.component';
import { TipoIncapacidadFormComponent } from './views/tipo-incapacidad/tipo-incapacidad-form/tipo-incapacidad-form.component';
import { IncapacidadEmpleadoComponent } from './views/incapacidad-empleado/incapacidad-empleado/incapacidad-empleado.component';
import { IncapacidadEmpleadoFormComponent } from './views/incapacidad-empleado/incapacidad-empleado-form/incapacidad-empleado-form.component';
import { DashboardPermisosLiderComponent } from './views/dashboard-permisos-lider/dashboard-permisos-lider.component';
import { DashboardPermisosRHComponent } from './views/dashboard-permisos-rh/dashboard-permisos-rh.component';
import { CardModule } from 'primeng/card';
import { environment } from 'src/environments/environment';
import { ListboxModule } from 'primeng/listbox';
import { TipoIncapacidadCatComponent } from './views/tipo-incapacidad/tipo-incapacidad-cat/tipo-incapacidad-cat.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    IncapacidadComponent,
    IncapacidadFormComponent,
    ListSolLiderFormComponent,
    ListSolRhFormComponent,
    HomeComponent,
    TipoIncapacidadComponent,
    TipoIncapacidadFormComponent,
    IncapacidadEmpleadoComponent,
    IncapacidadEmpleadoFormComponent,
    DashboardPermisosLiderComponent,
    DashboardPermisosRHComponent,
    TipoIncapacidadCatComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RestClientModule,
    HttpClientModule,
    ActionsBarModule,
    CommonsModule,
    DetailTabModule,
    OperationsBarModule,
    ResultListModule,
    FormsModule,
    BrowserAnimationsModule,
    CalendarModule,
    InputTextareaModule,
    DropdownModule,
    CheckboxModule,
    TableModule,
    ToastModule,
    ButtonModule,
    TreeTableModule,
    DialogModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    KeyFilterModule,
    CardModule,
    ListboxModule,
    ProgressSpinnerModule,
    TooltipModule
  ],
  providers: [MessageService, BaseMessages, SessionClientProvider,
    { provide: 'env', useValue: environment }],

  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TipoIncapacidad } from '../../../api/incapacidad/tipo-incapacidad';
import { TipoIncapacidadPsService } from '../../../procs/tipo-incapacidad/tipo-incapacidad-ps.service';

@Component({
  selector: 'axks-tipo-incapacidad-cat',
  templateUrl: './tipo-incapacidad-cat.component.html',
  styleUrls: ['./tipo-incapacidad-cat.component.scss']
})
export class TipoIncapacidadCatComponent implements OnInit {

  catalog: TipoIncapacidad[];
  @Input() value?: number;

 

  @Output() onValueChange = new EventEmitter<TipoIncapacidad>();

  constructor(private arestps: TipoIncapacidadPsService) { }

  ngOnInit(): void {

    
    this.arestps.find(File).subscribe(
      (data) => {
        this.catalog = data as TipoIncapacidad[];
      }
    );

  }

  onChange(event){
    let tcont = {
      id: this.value
    } as TipoIncapacidad;

    let tcf = this.catalog.find(tc => (tc.id == this.value));

    if(tcf && tcf.id === this.value){
      tcont.tipoIncapacidad = tcf.tipoIncapacidad
    }

    this.onValueChange.emit(tcont);
  }
}

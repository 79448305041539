import { Component, Input, OnInit } from '@angular/core';
import { ListSolLider } from 'src/app/api/list-solicitudes-lider/list-sol-lider';
import { ListSolLiderRestcService } from 'src/app/client/list-solicitudes-lider/list-sol-lider-restc.service';
import { MessageService } from 'primeng/api';
import { VistoBuenoLiderReg } from 'src/app/api/visto-bueno-lider/visto-bueno-lider-reg';
import { VistoBuenoLiderRestcService } from 'src/app/client/visto-bueno-lider/visto-bueno-lider-restc.service';
import { Message } from 'src/app/api/message';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'axks-list-sol-lider-form',
  templateUrl: './list-sol-lider-form.component.html',
  styleUrls: ['./list-sol-lider-form.component.scss']
})
export class ListSolLiderFormComponent extends BaseView implements OnInit {

  listaSol: ListSolLider[] = [];
  vistoBuenoLiderReg: VistoBuenoLiderReg = {};
  mensaje: Message[] = []
  profile = this.getProfile() as ProfileC;;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRestc: ListSolLiderRestcService,
    private vistoBuenoLiderRestc: VistoBuenoLiderRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    this.obtenerSolicitudes();
  }

  obtenerSolicitudes() {
    this.showProgress = true;

    this.listaRestc.solicitudesIncapacidadParaLider(this.profile.idEmpleado).subscribe(
      (data) => {
        this.listaSol = data as ListSolLider[];
        this.showProgress = false;
        if (this.listaSol.length == 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin solicitudes', detail: 'No hay ninguna incapacidad por visualizar' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener las solicitudes de incapacidad' });
      }
    );
  }

  vistoBueno(valor: any) {
    this.vistoBuenoLiderReg = {
      idIncapacidad: valor.idIncapacidad,
      idLider: this.profile.idEmpleado
    }

    this.showProgress = true;
    this.vistoBuenoLiderRestc.regVistoBuenoLider(this.vistoBuenoLiderReg).subscribe(
      (data) => {
        this.mensaje = data as Message[];
        this.showProgress = false;
        this.messageService.add({ severity: 'success', summary: 'Visto bueno exitoso', detail: 'Estás enterado de la solicitud de incapacidad' });
        setTimeout(function () { location.reload(); }, 1500);
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error al enviar la petición' });
      }
    );
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Dashboard } from 'src/app/api/dashboard/dashboard';
import { DashboardRHRestcService } from 'src/app/client/dashboard-RH/dashboard-rh-restc.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'axks-dashboard-permisos-rh',
  templateUrl: './dashboard-permisos-rh.component.html',
  styleUrls: ['./dashboard-permisos-rh.component.scss']
})
export class DashboardPermisosRHComponent extends BaseView implements OnInit {

  dashboardrh: Dashboard[] = [];
  selitem: Dashboard;
  showContent: boolean;
  myLoadContent: boolean;
  mensajeSol: string;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private client: DashboardRHRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.obtenerSolicitudes();
    }, 1500);
  }

  obtenerSolicitudes() {
    this.client.listaRH().subscribe(
      (data) => {
        this.dashboardrh = data as Dashboard[];
        this.myLoadContent = true;
        this.initContent();
      },
      (error) => {
        this.myLoadContent = true;
        this.mensajeSol = "Error. No se pudo consultar las solicitudes de incapacidades.";
      }
    );
  }

  initContent() {
    if (this.dashboardrh.length > 0) {
      this.showContent = true;
    }
    else {
      this.showContent = false;
      this.mensajeSol = "No hay solicitudes de incapacidades pendientes por revisar.";
    }
  }

  selSolicitud(event) {
    window.parent.postMessage({ for: "redirect", url: environment.urlIncapacidadRH }, "*");
  }
}
<div class="progress container" *ngIf="!myLoadContent">
    <p-progressSpinner class="progress-spinner" [style]="{width: '35px', height: '35px'}"
        styleClass="progress-spinner axks-progress-spinner" strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="myLoadContent">
    <p-listbox *ngIf="showContent" [options]="dashboardrh" [style]="{'width':'280px','border':'unset'}"
        (onClick)="selSolicitud($event)">
        <ng-template pTemplate="body" let-solicitud>
            <div>
                <i class="pi pi-check-square"></i>
            </div>
            <div style="padding-left: 0.6rem;">
                {{solicitud.incapacidad}} de
                {{solicitud.nombreEmpleado}} para el
                {{solicitud.fechaInicio | date: "dd 'de' MMMM 'de' yyyy": 'America/Mexico_City': 'es-MX'}}
            </div>
        </ng-template>
    </p-listbox>

    <div *ngIf="!showContent" [style]="{'width':'280px','border':'unset', 'max-height':'200px'}">
        <span style="font-size: 0.8rem;">{{mensajeSol}}</span>
    </div>
</ng-container>

<p-toast></p-toast>
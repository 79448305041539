import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Incapacidad } from 'src/app/api/incapacidad/incapacidad';
import { IncapacidadReg } from 'src/app/api/incapacidad/incapacidad-reg';
import { IncapacidadPsService } from 'src/app/procs/incapacidad/incapacidad-ps.service';


@Component({
  selector: 'axks-incapacidad',
  templateUrl: './incapacidad.component.html',
  styleUrls: ['./incapacidad.component.scss']
})
export class IncapacidadComponent extends BaseMainComponent<IncapacidadPsService> implements OnInit {

  constructor(
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvider: SessionClientProvider,
    private psService: IncapacidadPsService,) {

    super(deviceService, messageService, screenModeService, sessionProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as Incapacidad,
      minimumItem: {} as Incapacidad,
      registerItem: {
        idEmpleadoRH: this.profile.idEmpleado
      } as IncapacidadReg
    } as ComponentItem;
    return empty;
  }

  getService(): IncapacidadPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'Num. Solicitud', field: 'idIncapacidad', inAll: true },
      { header: 'Empleado', field: 'nombreEmpleado', inAll: true },
      { header: 'Tipo de incapacidad', field: 'tipoIncapacidad', inAll: true },
      { header: 'Motivo', field: 'comentario', inDetail: true },
      { header: 'Fecha inicio', field: 'fechaInicio', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha fin', field: 'fechaFin', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha de registro', field: 'fechaRegistroEmpleado', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha de autorización por RH', field: 'fechaRegistroRH', inAll: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return colsDef;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.idIncapacidad || this.item.item.idIncapacidad;
  }

  getComponentTitle(): string {
    return "Incapacidades";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = ['fechaInicio', 'fechaFin', 'idTipoIncapacidad', 'idEmpleado'];
    this.formFields = [];
  }

  formatoFecha(value: string) {
    let pipe = new DatePipe('es-MX');
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy");
  }

  save(event: any) {
    var mostrar1: any = document.getElementById('eFechaInicio');
    var mostrar2: any = document.getElementById('eFechaFin');
    var mostrar3: any = document.getElementById('eComentario');
    var mostrar4: any = document.getElementById('eIdTipoIncapacidad');
    var mostrar5: any = document.getElementById('eIdEmpleado');


    if (!this.item.registerItem.fechaInicio || this.item.registerItem.fechaInicio == "" ||
      !this.item.registerItem.fechaFin || this.item.registerItem.fechaFin == "" ||
      !this.item.registerItem.comentario || this.item.registerItem.comentario == "" ||
      !this.item.registerItem.idTipoIncapacidad || this.item.registerItem.idTipoIncapacidad == "" ||
      !this.item.registerItem.idEmpleado || this.item.registerItem.idEmpleado == "") {

      this.messageService.add({ severity: 'error', summary: 'Llena todos los campos', detail: '' });

      if (!this.item.registerItem.fechaInicio) {
        mostrar1.classList.add('mostrar-error');
        setTimeout(function () { mostrar1.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.fechaFin) {
        mostrar2.classList.add('mostrar-error');
        setTimeout(function () { mostrar2.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.comentario) {
        mostrar3.classList.add('mostrar-error');
        setTimeout(function () { mostrar3.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.idTipoIncapacidad) {
        mostrar4.classList.add('mostrar-error');
        setTimeout(function () { mostrar4.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.idEmpleado) {
        mostrar5.classList.add('mostrar-error');
        setTimeout(function () { mostrar5.classList.remove('mostrar-error'); }, 2000);
      }

    } else {
      super.save(this.item.registerItem);
      this.messageService.add({ severity: 'success', summary: 'Permiso registrado', detail: 'Se registro correctamente' });
    }
  }

  cargarTabla() {
    this.getService().find(this.item.item).subscribe(
      (data) => {
        this.resultList = data;
      },
      (error) => {
        try {
          let mess = error.error as Message;
          this.processMessage(mess);
        } catch (e) {
          console.debug(e);
        }
      }
    );
  }
}

<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <br>
        <span class="p-float-label">
            <input id="fl-tp-incap" type="text" pInputText [pKeyFilter]="alphabetic" [(ngModel)]="item.tipoIncapacidad">
            <label for="fl-tp-incap"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Tipo incapacidad</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
        <br>
        <span class="p-float-label">
            <input id="fl-coment" type="text" pInputText [pKeyFilter]="alphabetic" [(ngModel)]="item.comentario">
            <label for="fl-coment"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Comentario</label>
        </span>
    </div>
</div>
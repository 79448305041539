import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoIncapacidadRestService } from 'src/app/client/tipo-incapacidad/tipo-incapacidad-rest.service';

@Injectable({
  providedIn: 'root'
})
export class TipoIncapacidadPsService extends BaseProcess<TipoIncapacidadRestService> {

  constructor(httpClient: HttpClient, crest: TipoIncapacidadRestService) {
    super(httpClient, crest);
  }
}

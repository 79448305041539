import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, Message, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { IncapacidadEmpleado } from 'src/app/api/incapacidad-empleado/incapacidad-empleado';
import { IncapacidadEmpleadoReg } from 'src/app/api/incapacidad-empleado/incapacidad-empleado-reg';
import { IncapacidadPsService } from 'src/app/procs/incapacidad/incapacidad-ps.service';

@Component({
  selector: 'axks-incapacidad-empleado',
  templateUrl: './incapacidad-empleado.component.html',
  styleUrls: ['./incapacidad-empleado.component.scss']
})
export class IncapacidadEmpleadoComponent extends BaseMainComponent<IncapacidadPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvider: SessionClientProvider,
    private psService: IncapacidadPsService,) {
    super(deviceService, messageService, screenModeService, sessionProvider);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cargarTabla();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idEmpleado: this.profile.idEmpleado
      } as IncapacidadEmpleado,
      minimumItem: {
        idEmpleado: this.profile.idEmpleado
      } as IncapacidadEmpleado,
      registerItem: {
        idEmpleado: this.profile.idEmpleado,
        esProgramado: false
      } as IncapacidadEmpleadoReg
    } as ComponentItem;
    return empty;
  }

  getService(): IncapacidadPsService {
    return this.psService;
  }

  getColListDefinition(): ColumnDefinition[] {
    let colsDef = [
      { header: 'Num. Solicitud', field: 'idIncapacidad', inAll: true },
      { header: 'Tipo Incapacidad', field: 'tipoIncapacidad', inDetail: true, inResultList: true },
      { header: 'Comentario', field: 'comentario', inDetail: true, inResultList: true },
      { header: 'Fecha inicio', field: 'fechaInicio', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha fin', field: 'fechaFin', inAll: true, formatFunction: this.formatoFecha },
      { header: 'Fecha de registro (Por el empleado)', field: 'fechaRegistroEmpleado', inDetail: true, formatFunction: this.formatoFecha },
      { header: 'Fecha de registrado (Por RR.HH.)', field: 'fechaRegistroRH', inDetail: true, formatFunction: this.formatoFecha },
      { header: 'Estatus', field: 'autorizaRH', inAll: true, formatFunction: this.formatBoolean },
      { header: 'Fecha - Visto por el lider', field: 'fechaVistoLider', inAll: true, formatFunction: this.formatoFecha }
    ] as ColumnDefinition[];
    return colsDef;
  }
  getIdOfSelectedItem() {
    return this.item.minimumItem.idIncapacidad || this.item.item.idIncapacidad;
  }

  getComponentTitle(): string {
    return "Mis incapacidades";
  }

  componentsFields() {
    super.componentsFields();
    this.formSearch = ['fechaInicio', 'fechaFin'];
    this.formFields = [];
  }

  formatoFecha(value: string) {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy", "America/Mexico_City");
  }

  formatBoolean(value: any): string {
    if (value !== undefined && value !== "" && value !== null) {
      return (value == true ? 'Autorizado por RR.HH.' : 'Rechazado por RR.HH.');
    }
    else {
      return 'En revisión...';
    }
  }

  save(event: any) {
    var mostrar1: any = document.getElementById('eFechaInicio');
    var mostrar2: any = document.getElementById('eFechaFin');
    var mostrar3: any = document.getElementById('eComentario');
    var mostrar4: any = document.getElementById('eIdTipoIncapacidad');


    if (!this.item.registerItem.fechaInicio || this.item.registerItem.fechaInicio == "" ||
      !this.item.registerItem.fechaFin || this.item.registerItem.fechaFin == "" ||
      !this.item.registerItem.comentario || this.item.registerItem.comentario == "" ||
      !this.item.registerItem.idTipoIncapacidad || this.item.registerItem.idTipoIncapacidad == "") {

      this.messageService.add({ severity: 'error', summary: 'Llena todos los campos', detail: '' });

      if (!this.item.registerItem.fechaInicio) {
        mostrar1.classList.add('mostrar-error');
        setTimeout(function () { mostrar1.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.fechaFin) {
        mostrar2.classList.add('mostrar-error');
        setTimeout(function () { mostrar2.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.comentario) {
        mostrar3.classList.add('mostrar-error');
        setTimeout(function () { mostrar3.classList.remove('mostrar-error'); }, 2000);
      }
      if (!this.item.registerItem.idTipoIncapacidad) {
        mostrar4.classList.add('mostrar-error');
        setTimeout(function () { mostrar4.classList.remove('mostrar-error'); }, 2000);
      }

    } else {
      super.save(this.item.registerItem);
      this.messageService.add({ severity: 'success', summary: 'Incapacidad Registrada', detail: 'Se registro correctamente la incapacidad' });
    }
  }

  edit(event: any) {
    this.item.registerItem = this.item.item;
    this.save(this.item.registerItem);
  }

  cargarTabla() {
    this.getService().find(this.item.item).subscribe(
      (data) => {
        this.resultList = data;
      },
      (error) => {
        try {
          let mess = error.error as Message;
          this.processMessage(mess);
        } catch (e) {
          console.debug(e);
        }
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { ListSolRh } from 'src/app/api/list-solicitudes-rh/list-sol-rh';
import { ListSolRhRestcService } from 'src/app/client/list-solicitudes-rh/list-sol-rh-restc.service';
import { MessageService } from 'primeng/api';
import { IncapacidadAutorizaRhReg } from 'src/app/api/incapacidad-autoriza-rh/incapacidad-autoriza-rh-reg';
import { Message } from 'src/app/api/message';
import { IncapacidadAutorizaRhRestcService } from 'src/app/client/incapacidad-autoriza-rh/incapacidad-autoriza-rh-restc.service';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
  selector: 'axks-list-sol-rh-form',
  templateUrl: './list-sol-rh-form.component.html',
  styleUrls: ['./list-sol-rh-form.component.scss']
})
export class ListSolRhFormComponent extends BaseView implements OnInit {

  profile = this.getProfile() as ProfileC;
  listaSol: ListSolRh[] = [];
  incapacidadAutorizaRhReg: IncapacidadAutorizaRhReg = {};
  mensaje: Message[] = [];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private listaRestc: ListSolRhRestcService,
    private incapacidadAutorizaRhRestc: IncapacidadAutorizaRhRestcService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    this.obtenerSolicitudes();
  }

  obtenerSolicitudes() {
    this.showProgress = true;
    this.listaRestc.solicitudesIncapaciadParaRH().subscribe(
      (data) => {
        this.listaSol = data as ListSolRh[];
        this.showProgress = false;
        if (this.listaSol.length == 0) {
          this.messageService.add({ severity: 'info', summary: 'Sin solicitudes', detail: 'No hay ninguna incapacidad pendiente por autorizar' });
        }
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener las solicitudes' });
      }
    );
  }

  autorizar(valor: any) {
    this.incapacidadAutorizaRhReg = {
      autorizacion: true,
      idEmpleadoRH: this.profile.idEmpleado,
      idIncapacidad: valor.idIncapacidad
    }

    this.showProgress = true;

    this.incapacidadAutorizaRhRestc.regAutorizaIncapacidad(this.incapacidadAutorizaRhReg).subscribe(
      (data) => {
        this.mensaje = data as Message[];
        this.showProgress = false;
        this.messageService.add({ severity: 'success', summary: 'Solicitud autorizada', detail: 'Se autorizo la solicitud correctamente' });
        setTimeout(function () { location.reload(); }, 1500);
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar la solicitud' });
      }
    );
  }

  rechazar(valor: any) {
    this.incapacidadAutorizaRhReg = {
      autorizacion: false,
      idEmpleadoRH: this.profile.idEmpleado,
      idIncapacidad: valor.idIncapacidad
    }

    this.showProgress = true;

    this.incapacidadAutorizaRhRestc.regAutorizaIncapacidad(this.incapacidadAutorizaRhReg).subscribe(
      (data) => {
        this.mensaje = data as Message[];
        this.showProgress = false;
        this.messageService.add({ severity: 'success', summary: 'Solicitud rechazada', detail: 'Se rechazo la solicitud correctamente' });
        setTimeout(function () { location.reload(); }, 1500);
      },
      (error) => {
        this.showProgress = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar la solicitud' });
      }
    );
  }

}

<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaInicio')">
        <p class="color error" id="eFechaInicio">Selecciona un valor</p>
        <span class="p-float-label">
            <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                [disabledDays]="[0,6]"></p-calendar>
            <label for="fl-fecin"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Fecha de Inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('fechaFin')">
        <p class="color error" id="eFechaFin">Selecciona un valor</p>
        <span class="p-float-label">
            <p-calendar id="fl-fecfn" [(ngModel)]="item.fechaFin" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icon" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                [disabledDays]="[0,6]"></p-calendar>
            <label for="fl-fecfn"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Fecha Fin</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('idTipoIncapacidad')">
        <p class="color error" id="eIdTipoIncapacidad">Selecciona un valor</p>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tpperm" [(ngModel)]="item.idTipoIncapacidad" [options]="catTpIncapacidad"
                optionLabel="tipoIncapacidad" optionValue="id" [autoDisplayFirst]="false" autoWidth="false">
            </p-dropdown>
            <label for="fl-tpperm"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Tipo de Incapacidad</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('comentario')">
        <p class="color error" id="eComentario">Ingresa un comentario</p>
        <span class="p-float-label">
            <textarea rows="3" cols="30" pInputTextarea [pKeyFilter]="alphanumeric"
                [(ngModel)]="item.comentario" [style]="{'width': '100%'}"></textarea>
            <label for="fl-coment"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Comentario</label>
        </span>
    </div>

    <br>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12" *ngIf="allowFormFields('esProgramado')">
        <p-checkbox [(ngModel)]="item.esProgramado" [binary]="true" inputId="esProgramado"></p-checkbox>
        &nbsp; <label for="prog" class="form-field-label" style="font-size: 0.8rem; color: #666666;">Programar incapacidad</label>
    </div>

</div>

<p-toast></p-toast>
import { formatDate, registerLocaleData } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Incapacidad } from 'src/app/api/incapacidad/incapacidad';
import { CatalogosRestService } from 'src/app/client/incapacidad/catalogos-rest.service';

import { TipoIncapacidad } from 'src/app/api/incapacidad/tipo-incapacidad';
import { Empleado } from 'src/app/api/incapacidad/empleado';

import localeEsMx from '@angular/common/locales/es-MX';/*localizacion para dar formato*/
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { EmpleadoNombre } from '../../../../api/empleado/empleado-nombre';
registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'axks-incapacidad-form',
  templateUrl: './incapacidad-form.component.html',
  styleUrls: ['./incapacidad-form.component.scss']
})
export class IncapacidadFormComponent extends BaseView implements OnInit {

  @Input() item: Incapacidad;
  @Input() profile: ProfileC;

  yearRange: any;
  catTpIncapacidad: TipoIncapacidad[] = [];
  catEmpleados: EmpleadoNombre[];
  alphanumeric: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;
  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;
  op = OperationMode;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvider: SessionClientProvider,
    private catRest: CatalogosRestService) {

    super(screenModeService, deviceService, messageService, sessionProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {} as Incapacidad;
    }

    this.initDates();
    this.initCatalogos();
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();
    this.yearRange = (year - 60) + ':' + (year + 60);
  }

  initCatalogos() {
    // Tipos de incapacidades
    this.catRest.catTipoIncapacidad().subscribe(
      (data) => {
        this.catTpIncapacidad = data as TipoIncapacidad[];
      },
      (error) => {
        this.errorMessage("Error de catalogo", "No se pudo obtener los tipos de incapacidades");
      }
    );

    // Empleados con contrato activo
    this.catRest.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data as EmpleadoNombre[];
      },
      (error) => {
        this.errorMessage("Error de catalogo", "No se pudo obtener los empleados con contrato activo");
      }
    );
  }
}

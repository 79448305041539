<!-- {{item | json}} -->
<div class="p-grid frm-part">

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idEmpleado')">
        <p class="color error" id="eIdEmpleado">Selecciona un valor</p>
        <span class="p-float-label">
            <p-dropdown [options]="catEmpleados" [pKeyFilter]="alphabetic" [(ngModel)]="item.idEmpleado"
                optionLabel="nombreCompleto" optionValue="id" [autoDisplayFirst]="false" autoWidth="false"
                [filter]="true" filterBy="nombreCompleto">
            </p-dropdown>
            <label for="fl-emp"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span>
                Empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idTipoIncapacidad')">
        <p class="color error" id="eIdTipoIncapacidad">Selecciona un valor</p>
        <span class="p-float-label">
            <p-dropdown inputId="fl-tpart" [(ngModel)]="item.idTipoIncapacidad" [options]="catTpIncapacidad"
                optionLabel="tipoIncapacidad" optionValue="id" [autoDisplayFirst]="false" autoWidth="false">
            </p-dropdown>
            <label for="fl-tpart"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Tipo de
                Incapacidad</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fechaInicio')">
        <p class="color error" id="eFechaInicio">Selecciona un valor</p>
        <span class="p-float-label">
            <p-calendar id="fl-fecin" [(ngModel)]="item.fechaInicio" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icono" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                [disabledDays]="[0,6]"></p-calendar>
            <label for="fl-fecin"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Fecha de
                inicio</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('fechaFin')">
        <p class="color error" id="eFechaFin">Selecciona un valor</p>
        <span class="p-float-label">
            <p-calendar id="fl-fecfn" [(ngModel)]="item.fechaFin" dateFormat="yy/mm/dd" [yearNavigator]="true"
                [showIcon]="true" inputId="icon" [yearRange]="yearRange" [monthNavigator]="true" dataType="string"
                [disabledDays]="[0,6]"></p-calendar>
            <label for="fl-fecfn"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span> Fecha
                Fin</label>
        </span>

    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('comentario')">
        <p class="color error" id="eComentario">Ingresa un comentario</p>
        <span class="p-float-label">
            <textarea rows="3" cols="30" inputId="fl-coment" pInputTextarea [pKeyFilter]="alphanumeric"
                [(ngModel)]="item.comentario" [style]="{'width':'100%'}"></textarea>
            <label for="fl-coment"><span *ngIf="operationMode == op.ADDNEW" [style]="{'color':'red'}">*</span>
                Comentario</label>
        </span>
    </div>

    <br>
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('recalculoVacaciones')">
        <p-checkbox [(ngModel)]="item.recalculoVacaciones" [binary]="true" inputId="recalculoVacaciones"></p-checkbox>
        &nbsp; <label for="recalcVaca" class="form-field-label" style="font-size: 0.8em;">Recalcular Vacaciones</label>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('programadas')">
        <p-checkbox [(ngModel)]="item.esProgramado" [binary]="true" inputId="esProgramado"></p-checkbox>
        &nbsp; <label for="prog" class="form-field-label" style="font-size: 0.8em;">Programar incapacidad</label>
    </div>

</div>
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppClient, SessionClientProvider } from '@axks/components';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AppClient {
  title = 'axks-vacaciones-gui';

  constructor(
    protected router: Router,
    protected messageService: MessageService,
    protected sessionProvider: SessionClientProvider,
    @Inject(DOCUMENT) private docs: Document
  ) {
    super(environment, router, messageService, sessionProvider, docs)

  }
}

import { registerLocaleData } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IncapacidadEmpleado } from 'src/app/api/incapacidad-empleado/incapacidad-empleado';
import { CatalogosRestService } from 'src/app/client/incapacidad/catalogos-rest.service';
import { TipoIncapacidad } from 'src/app/api/tipo-incapacidad/tipo-incapacidad';
import localeEsMx from '@angular/common/locales/es-MX';
import { MessageService } from 'primeng/api';
import { ProfileC } from '@axks/components/lib/api/views/profile';
registerLocaleData(localeEsMx, 'es-MX');

@Component({
  selector: 'axks-incapacidad-empleado-form',
  templateUrl: './incapacidad-empleado-form.component.html',
  styleUrls: ['./incapacidad-empleado-form.component.scss']
})
export class IncapacidadEmpleadoFormComponent extends BaseView implements OnInit {

  @Input() item: IncapacidadEmpleado;

  op = OperationMode;
  yearRange: any;
  catTpIncapacidad: TipoIncapacidad[] = [];
  alphanumeric: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°+|*¬¸~─,¨:;-]/;
  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvider: SessionClientProvider,
    private catRest: CatalogosRestService) {
    super(screenModeService, deviceService, messageService, sessionProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }

    this.obtenerIdEmp();
    this.initDates();
    this.initCatalogos();
  }

  obtenerIdEmp() {
    let profile = this.getProfile() as ProfileC;;
    this.item.idEmpleado = profile.idEmpleado;
  }

  initDates() {
    let now = new Date();
    let year = now.getFullYear();

    this.yearRange = (year - 60) + ':' + (year + 60);
  }

  initCatalogos() {

    this.catRest.catTipoIncapacidad().subscribe(
      data => {
        this.catTpIncapacidad = data as TipoIncapacidad[];
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IncapacidadComponent } from './views/incapacidad/incapacidad/incapacidad.component';
import { ListSolLiderFormComponent } from './views/list-sol-lider-form/list-sol-lider-form.component';
import { ListSolRhFormComponent } from './views/list-sol-rh-form/list-sol-rh-form.component';
import { HomeComponent } from './views/home/home.component';
import { TipoIncapacidadComponent } from './views/tipo-incapacidad/tipo-incapacidad/tipo-incapacidad.component';
import { IncapacidadEmpleadoComponent } from 'src/app/views/incapacidad-empleado/incapacidad-empleado/incapacidad-empleado.component';
import { DashboardPermisosLiderComponent } from './views/dashboard-permisos-lider/dashboard-permisos-lider.component';
import { DashboardPermisosRHComponent } from './views/dashboard-permisos-rh/dashboard-permisos-rh.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: 'incapacidad', component: IncapacidadComponent },
  { path: 'incapacidad-empleado', component: IncapacidadEmpleadoComponent },
  { path: 'incapacidad-lider', component: ListSolLiderFormComponent },
  { path: 'incapacidad-rh', component: ListSolRhFormComponent },
  { path: 'tipo-incapacidad', component: TipoIncapacidadComponent },
  { path: 'dash-incapacidad-lider', component: DashboardPermisosLiderComponent },
  { path: 'dash-incapacidad-RH', component: DashboardPermisosRHComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { TipoIncapacidad } from 'src/app/api/tipo-incapacidad/tipo-incapacidad';

@Component({
  selector: 'axks-tipo-incapacidad-form',
  templateUrl: './tipo-incapacidad-form.component.html',
  styleUrls: ['./tipo-incapacidad-form.component.scss']
})
export class TipoIncapacidadFormComponent extends BaseView implements OnInit {

  @Input() item: TipoIncapacidad = {};

  op = OperationMode;
  alphabetic: RegExp = /[^\{}._?¡¿!"#$%&/()=<·>[''@´°1234567890+|*¬¸~─,¨:;-]/;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvider: SessionClientProvider) {

    super(screenModeService, deviceService, messageService, sessionProvider);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {};
    }
  }
}
import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "incapacidades-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  msIncapacidad: 'https://rh.uat-ms.axkans.org/rh/incapacidad',
  msTipoIncapacidad: 'https://rh.uat-ms.axkans.org/rh/tipo-incapacidad/all',
  msEmpleadosActivos: 'https://rh.uat-ms.axkans.org/rh/empleado/ps/empleados-contrato-activo',
  msListSolicitudes: 'https://rh.uat-ms.axkans.org/rh/incapacidad/lista-incapacidad-lider',
  msListSolicitudesRh: 'https://rh.uat-ms.axkans.org/rh/incapacidad/lista-incapacidad-RH',
  msTpIncapacidadReg: 'https://rh.uat-ms.axkans.org/rh/tipo-incapacidad',
  msVistoBuenoLider: 'https://rh.uat-ms.axkans.org/rh/incapacidad/visto-bueno-lider',
  msAutorizaIncapacidadRh: 'https://rh.uat-ms.axkans.org/rh/incapacidad/autoriza-incapacidad-RH',
  msDashboardLider: 'https://rh.uat-ms.axkans.org/rh/incapacidad/dashboard-lider',
  msDashboardRH: 'https://rh.uat-ms.axkans.org/rh/incapacidad/dashboard-RH',
  urlIncapacidadLider: 'https://incapacidad.uat-gui.axkans.org/incapacidad-lider',
  urlIncapacidadRH: 'https://incapacidad.uat-gui.axkans.org/incapacidad-rh',
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
};

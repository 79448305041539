import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmpleadoNombre } from 'src/app/api/empleado/empleado-nombre';
import { Empleado } from 'src/app/api/incapacidad/empleado';
import { TipoIncapacidad } from 'src/app/api/incapacidad/tipo-incapacidad';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogosRestService {

  constructor(private httpClient: HttpClient) { }

  catTipoIncapacidad(): Observable<TipoIncapacidad> {
    let path = environment.msTipoIncapacidad;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers });
  }

  empleadosContratoActivo(idEmpresa: number): Observable<EmpleadoNombre[]> {
    let path = environment.msEmpleadosActivos + "/" + idEmpresa;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.get(path, { headers }) as Observable<EmpleadoNombre[]>;
  }
}
